import React, { useState, useEffect } from "react";
import { Box, Typography, FormControlLabel } from "@mui/material";
import Button from "@mui/material/Button";
import Switch from "../../components/Switch";
import {
  useGetUserSettingTasksQuery,
  useUpdateUserPreferencesMutation,
} from "../../services/usersSlice";
import { userTaskSettings } from "../../../shared/types/User"; 

const UserProfileSettings = () => {
  const [updateUserPreferences] = useUpdateUserPreferencesMutation();
  const { data: getTaskSetting, refetch } = useGetUserSettingTasksQuery();
  const [hideMyPendingTasks, setHideMyPendingTasks] = useState(false);
  const [hideMyCompleteTasks, setHideMyCompleteTasks] = useState(false);
  const [hideOtherPendingTasks, setHideOtherPendingTasks] = useState(false);
  const [hideOtherCompleteTasks, setHideOtherCompleteTasks] = useState(false);

  useEffect(() => {
    if (getTaskSetting && getTaskSetting.userProfileSettings) {
      const settings = JSON.parse(getTaskSetting.userProfileSettings);
      const {
        hideMyPendingTasks,
        hideMyCompleteTasks,
        hideOtherPendingTasks,
        hideOtherCompleteTasks,
      } = settings;
      setHideMyPendingTasks(hideMyPendingTasks);
      setHideMyCompleteTasks(hideMyCompleteTasks);
      setHideOtherPendingTasks(hideOtherPendingTasks);
      setHideOtherCompleteTasks(hideOtherCompleteTasks);
    }
  }, [getTaskSetting]);

  const taskSettings = {
    hideMyPendingTasks,
    hideMyCompleteTasks,
    hideOtherPendingTasks,
    hideOtherCompleteTasks,
  };

  const updateUserSettings = async () => {
    await updateUserPreferences({
      body: {
        userProfileSettings: taskSettings,
      },
    });
    refetch();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#f5f5f5",
          borderRadius: "16px",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          maxWidth: "1000px",
          margin: "auto",
        }}
      >
        <h1>{userTaskSettings.profilePageHeading}</h1>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <Typography sx={{ font: "normal normal 600 18px/24px Open Sans;" }}>
            {userTaskSettings.myTasks}
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={hideMyPendingTasks}
                onChange={() => setHideMyPendingTasks(!hideMyPendingTasks)}
              />
            }
            sx={{ marginTop: "50px", marginLeft: "60px" }}
            label={
              <Typography sx={{ mr: 2 }}>{userTaskSettings.hidePendingTasks}</Typography>
            }
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Switch
                checked={hideMyCompleteTasks}
                onChange={() => setHideMyCompleteTasks(!hideMyCompleteTasks)}
              />
            }
            sx={{ marginTop: "50px", marginLeft: "60px" }}
            label={
              <Typography sx={{ mr: 2 }}>{userTaskSettings.hideCompleteTasks}</Typography>
            }
            labelPlacement="start"
          />
        </div>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <Typography sx={{ font: "normal normal 600 18px/24px Open Sans;" }}>
            {userTaskSettings.otherTasks}
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={hideOtherPendingTasks}
                onChange={() =>
                  setHideOtherPendingTasks(!hideOtherPendingTasks)
                }
              />
            }
            sx={{ marginTop: "50px", marginLeft: "60px" }}
            label={
              <Typography sx={{ mr: 2 }}>{userTaskSettings.hidePendingTasks}</Typography>
            }
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Switch
                checked={hideOtherCompleteTasks}
                onChange={() =>
                  setHideOtherCompleteTasks(!hideOtherCompleteTasks)
                }
              />
            }
            sx={{ marginTop: "50px", marginLeft: "60px" }}
            label={
              <Typography sx={{ mr: 2 }}>{userTaskSettings.hideCompleteTasks}</Typography>
            }
            labelPlacement="start"
          />
        </div>
        <Button
          variant="outlined"
          sx={{ marginTop: "50px", textTransform: "capitalize" }}
          onClick={updateUserSettings}
        >
          Save Settings
        </Button>
      </Box>
    </>
  );
};

export default UserProfileSettings;
