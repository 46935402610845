import { sepAPI } from '../API';
import { User, SearchParams, UserProfileSettings } from '../../../shared/types/User';
import { setSnackbarForEndpoint } from '../../utils/snackbar';

export const usersSlice = sepAPI.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, string | undefined>({
      query: (id) => `users/${id}`,
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem getting this user.',
        });
      },
    }),
    getUserSettingTasks: builder.query<User, void>({
      query: () => `user/settings`,
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem getting this user.',
        });
      },
    }),
    updateUserPreferences: builder.mutation<null, {body: UserProfileSettings}>({
      query: (data) => {
        return {
          url: `user-profile-settings`,
          method: `PATCH`,
          body: { data },
        };
      },
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'User Profile Settings updated successfully!',
          errorMessage: 'There was a problem in updating user settings.',
        });
      },
    }),
    searchUsers: builder.query<User[], SearchParams>({
      query: (arg) => {
        const { search, adAppRoleName } = arg;
        return {
          url: `users`,
          params: {
            search: search,
            adAppRoleName: adAppRoleName,
          },
        };
      },
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem getting this users.',
        });
      },
    }),
  }),
});

export const { useGetUserQuery, useGetUserSettingTasksQuery, useUpdateUserPreferencesMutation,  useSearchUsersQuery } = usersSlice;
