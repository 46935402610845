//Containers
import * as React from 'react';
import AppContainer from '../containers/AppContainer';

//containers
import KnockoutContainer from '../containers/KnockoutContainer';
import SepDetailsContainer from '../containers/SepDetailsContainer';
import SepContainer from '../containers/SepContainer';
import AdminContainer from '../containers/AdminContainer';

//Route components
import AllSEPs from '../features/allSEPs/AllSEPs';
import MySEPs from '../features/mySEPs/MySEPs';
import NotFound from '../features/pages/NotFound';
import Knockout from '../features/Knockout';
import CreateSEP from '../features/CreateSEP';
import Auth from '../features/auth/Auth';
import SEP from '../features/SEP/SEP';
import Admin from '../features/Admin/Admin';
import { DoINeedSEP } from '../features/DoINeedSEP/DoINeedSEP';

import { createBrowserRouter } from 'react-router-dom';
import SepDetails from '../features/SepDetails/SepDetails';
import { Unsubscribe } from '../features/UnSubscribeSEP/UnSubscribe'
import UserProfileSettings from '../features/UserProfileSettings/UserProfileSettings';

export const routes = createBrowserRouter([
  {
    element: <Auth />,
    children: [
      {
        path: '*',
        element: <NotFound />,
      },
      {
        element: <AppContainer />,
        children: [
          {
            element: <SepContainer />,
            children: [
              {
                index: true,
                path: '/',
                element: <MySEPs />,
              },
              {
                path: '/all-seps',
                element: <AllSEPs />,
              },
              {
                path: '/details',
                element: <SepDetailsContainer />,
              },
              {
                path: '/user-profile-settings',
                element: <UserProfileSettings />,
              },
              {
                path: 'sep',
                children: [
                  {
                    path: ':sepId',
                    element: <SEP />,
                  },
                  {
                    path: ':sepId/unsubscribe',
                    element: <Unsubscribe />,
                  },
                  {
                    path: ':sepId/task/:taskId',
                    element: <SEP />,
                  },
                ],
              },
            ],
          },
          {
            path: 'details',
            element: <SepContainer />,
            children: [
              {
                path: ':sepId',
                element: <SepDetails />,
              },
            ],
          },
          {
            path: '/admin/',
            element: <AdminContainer />,
            children: [
              {
                path: '',
                element: <Admin />,
              },
            ],
          },
        ],
      },
      {
        path: '/create',
        element: <KnockoutContainer />,
        children: [
          {
            path: '',
            element: <CreateSEP />,
          },
        ],
      },
      // {
      //   path: '/do-i-need-sep',
      //   element: <KnockoutContainer />,
      //   children: [
      //     {
      //       path: '',
      //       element: <DoINeedSEP />,
      //     },
      //   ],
      // },
      {
        path: '/knockout/',
        element: <KnockoutContainer />,
        children: [
          {
            path: ':sepId',
            element: <Knockout />,
          },
        ],
      },
    ],
  },
]);
