import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Box,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const SepSubscriptionsModal = (
  {subscribedSeps, handleSave}:{subscribedSeps:any, handleSave:any}
) => {

const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.solidBlue.main,
    '&:hover': {
      color: theme.palette.gray.main,
    },
}));
  
  return (
    <Box sx={{ p: 3, maxHeight: '500px', overflow: 'auto' }}>
      {subscribedSeps?.length >0 ?
      <Table sx={{ width: '100%' }} aria-labelledby="tableTitle" size="medium">
        <TableHead>
          <TableRow>
            <TableCell>SEP ID</TableCell>
            <TableCell>SEP Name</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscribedSeps.map((sep:any, index:number) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  <StyledLink to={`/sep/${sep.id}`}>{sep.id}</StyledLink>
                </TableCell>
                <TableCell>{sep.name}</TableCell>
                <TableCell>
                  <Button onClick={()=>handleSave(sep.id,sep.subscribers)}>Unsubscribe</Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table> : <div>No SEPs  subscribed yet</div>
      }
    </Box>
  );
};

export default SepSubscriptionsModal;
