import { setSnackbarForEndpoint } from '../../utils/snackbar';
import { sepAPI } from '../API/sepAPI';
import {
  Department,
  DepartmentExtended,
  CreateDepartmentBody,
  UpdateDepartmentBody,
  reOrderDepartmentBody
} from '../../../shared/types/Department';
import { DefaultStakeholderExtended } from '../../../shared/types/DefaultStakeholder';

export const departmentSlice = sepAPI.injectEndpoints({
  endpoints: (builder) => ({
    getDepartments: builder.query<Department[], void>({
      query: () => `departments`,
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem getting the list of departments',
        });
      },
    }),

    createDepartment: builder.mutation<Department, CreateDepartmentBody>({
      query: (data) => {
        return {
          url: `departments`,
          method: `POST`,
          body: { ...data },
        };
      },
      invalidatesTags: ['Departments'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Department created successfully!',
          errorMessage: 'There was a problem creating your Department.',
        });
      },
    }),

    editDepartment: builder.mutation<
      Department,
      { data: UpdateDepartmentBody; id: number }
    >({
      query: ({ data, id }) => {
        return {
          url: `department/${id}`,
          method: 'PATCH',
          body: {
            ...data,
          },
        };
      },
      invalidatesTags: ['Departments'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Department updated successfully!',
          errorMessage: 'There was a problem updating your Department.',
        });
      },
    }),

    reOrderDepartment: builder.mutation<
      void,
      reOrderDepartmentBody
    >({
      query: ({ payload }) => ({
          url: 'department-order',
          method: 'PATCH',
          body: payload
        }),
      invalidatesTags: ['Departments'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Department order updated successfully!',
          errorMessage: 'There was a problem in updating your Department order.',
        });
      },
    }),

    deleteDepartment: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: `department/${id}`,
          method: `DELETE`,
        };
      },
      invalidatesTags: ['Departments'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Department deleted successfully!',
          errorMessage: 'There was a problem deleting your Department.',
        });
      },
    }),

    getDepartmentsExtended: builder.query<DepartmentExtended[], void>({
      query: () => `departments-extended`,
      providesTags: ['Departments'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem getting the list of departments',
        });
      },
    }),
    getUserDisplayNames: builder.query<DepartmentExtended[], void>({
      query: () => `user-names`,
      providesTags: ['Departments'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem getting the list of departments',
        });
      },
    }),
    getDefaultStakeholdersForSEP: builder.query<
      DefaultStakeholderExtended[],
      number
    >({
      query: (sepID: number) => `default-stakeholders/${sepID}`,
      providesTags: ['DefaultStakeholdersForSEP'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage:
            'There was a problem getting the list of default stakeholders for this SEP',
        });
      },
    }),
    setDefaultStakeholderForSEPDepartment: builder.mutation<
      void,
      { sepID: number; departmentID: number; userID: string }
    >({
      query: ({ sepID, departmentID, userID }) => {
        return {
          url: `default-stakeholder/${sepID}/dept/${departmentID}`,
          method: 'PATCH',
          body: { userID },
        };
      },
      invalidatesTags: ['DefaultStakeholdersForSEP', 'TasksForSEP'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage:
            'There was a problem setting the default stakeholder for this department',
        });
      },
    }),
  }),
});

export const {
  useGetDepartmentsQuery,
  useGetDefaultStakeholdersForSEPQuery,
  useSetDefaultStakeholderForSEPDepartmentMutation,
  useGetDepartmentsExtendedQuery,
  useGetUserDisplayNamesQuery,
  useCreateDepartmentMutation,
  useDeleteDepartmentMutation,
  useEditDepartmentMutation,
  useReOrderDepartmentMutation
} = departmentSlice;
