import { SequelizeTimestamps } from './Sequelize';

/** A User in the system. Created when an active directory user first accesses the application. */
export interface User extends SequelizeTimestamps {
  id: string;
  familyName?: string;
  givenName?: string;
  upn?: string;
  officeLocation?: string;
  email?: string;
  department?: string;
  displayName?: string;
  surname?: string;
  jobTitle?: string;
  userProfileSettings?: any
  hideMyPendingTasks?: any;
  hideMyCompleteTasks?: any;
  hideOtherPendingTasks?: any;
  hideOtherCompleteTasks?: any;
}

export interface UserShort {
  id: string;
  email?: string;
  displayName?: string;
  givenName?: string;
  surname?: string;
}

export interface SearchParams {
  search: string;
  adAppRoleName?: string;
}

export interface RequestBody {
  taskID: number;
  user: User;
}

export interface UserProfileSettings {
  userProfileSettings?: any
}

export enum  userTaskSettings {
  profilePageHeading = 'User Profile Page Settings',
  myTasks = 'My Tasks',
  otherTasks = 'Tasks for Others',
  hidePendingTasks = 'Hide Pending Tasks',
  hideCompleteTasks = 'Hide Complete Tasks',
}